import request from '@/utils/request'

export function WxSaveCheck (data) {
  return request({
    url: 'CheckRisk/WxSaveCheck',
    method: 'post',
    data: data
  })
}

export function WeGetEnterpriseInfo (query) {
  return request({
    url: 'CheckRisk/WeGetEnterpriseInfo',
    method: 'get',
    params: query
  })
}
// 获取返洛人员报备详情
export function WxGetReporting (query) {
  return request({
    url: 'Vaccine/WxGetReporting',
    method: 'get',
    params: query
  })
}
// 获取指定返洛登记记录的核酸检测报备记录
export function WxGetNACheckPage (query) {
  return request({
    url: 'Vaccine/WxGetNACheckPage',
    method: 'get',
    params: query
  })
}

// 居民端-获取物业公司详情
export function WxGetProperty (query) {
  return request({
    url: 'Property/WxGetProperty',
    method: 'get',
    params: query
  })
}
// 居民端-获取指定物业公司的服务列表
export function WxGetProServiceList (query) {
  return request({
    url: 'Property/WxGetProServiceList',
    method: 'get',
    params: query
  })
}
// 居民端-获取物业公司服务详情
export function WxGetProService (query) {
  return request({
    url: 'Property/WxGetProService',
    method: 'get',
    params: query
  })
}